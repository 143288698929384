<template>
  <div>
    <div class="loading" v-if="isLoading">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-card>
      <b-card-header class="header-card">
        <div class="d-flex align-items-center justify-content-between">
          <p class="m-0">
            Threshold activated Today <span>|</span>
            <span>{{ currentDate }}</span>
          </p>
        </div>
      </b-card-header>
      <b-card-body class="p-4">
        <b-row>
          <b-col sm="6">
            <b-card>
              <b-card-body class="card-treshold-type p-4">
                <div class="icon-treshold icon-treshold-top">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <div class="content-treshold">
                  <h5 class="mb-1">Buy Price</h5>
                  <div class="item-content-treshold">
                    <p>Gold Buy Price</p>
                    <p class="value">{{ dataSummaries.buyPrice.gold }}</p>
                  </div>
                  <div class="item-content-treshold">
                    <p>Top Threshold</p>
                    <p class="value">{{ dataSummaries.buyPrice.max }}</p>
                  </div>
                  <div class="item-content-treshold">
                    <p>Bottom Threshold</p>
                    <p class="value">{{ dataSummaries.buyPrice.min }}</p>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card>
              <b-card-body class="card-treshold-type p-4">
                <div class="icon-treshold icon-treshold-bottom">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <div class="content-treshold">
                  <h5 class="mb-1">Sell Price</h5>
                  <div class="item-content-treshold">
                    <p>Gold Sell Price</p>
                    <p class="value">{{ dataSummaries.sellPrice.gold }}</p>
                  </div>
                  <div class="item-content-treshold">
                    <p>Top Threshold</p>
                    <p class="value">{{ dataSummaries.sellPrice.max }}</p>
                  </div>
                  <div class="item-content-treshold">
                    <p>Bottom Threshold</p>
                    <p class="value">{{ dataSummaries.sellPrice.min }}</p>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            sm="12"
            class="d-flex align-items-center justify-content-start"
          >
            <p class="m-0 mr-3">
              Created At: <strong>{{ dataSummaries.createdAt }}</strong>
            </p>
            <p class="m-0">
              Created By: <strong>{{ dataSummaries.createdBy }}</strong>
            </p>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="header-card">
        <p>Threshold List</p>
      </b-card-header>
      <b-card-body class="p-4">
        <b-row class="mb-3" v-if="$can('export')">
          <b-col sm="12" class="mb-4 d-flex justify-content-start">
            <a
              href="create-threshold-control"
              class="card-header-action mr-3"
              rel="noreferrer noopener"
            >
              <button class="btn btn-info text-white" type="button">
                <i class="fa fa-plus"></i>
                Create Threshold
              </button>
            </a>
            <button
              type="button"
              class="btn btn-success"
              @click="exportTable"
              :style="{ width: '100px' }"
              :disabled="stateButtonExport"
            >
              <div class="sk-three-bounce loading-btn" v-if="isLoadingExport">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
              </div>
              <p v-else>
                Export
              </p>
            </button>
          </b-col>
          <b-col sm="7" class="mb-5">
            <b-form-group class="mb-3" label="Date Range" label-for="daterange">
              <b-input-group>
                <b-input-group-prepend class="prepend-dateType">
                  <b-form-select
                    v-model="dateType"
                    :options="optionsDateType"
                  ></b-form-select>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  name="filterDate"
                  v-model="filterDate"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button
                    type="button"
                    variant="secondary"
                    @click="resetDateFilter"
                    >Reset</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <b-form-text class="text-danger" v-show="filterDateError">
                {{ filterDateError }}
              </b-form-text>
            </b-form-group>
            <button
              class="btn"
              type="button"
              :disabled="stateButton"
              :style="buttonStyle"
              @click="doFilter"
            >
              Apply Filter
            </button>
          </b-col>
          <b-col sm="12">
            <div v-if="loadingTable">
              <b-skeleton-table
                :rows="10"
                :columns="6"
                :table-props="{ bordered: true }"
              >
              </b-skeleton-table>
            </div>
            <vuetable
              v-else
              ref="vuetable"
              :api-mode="false"
              :fields="fields"
              :per-page="limit"
              :data-manager="dataManager"
              pagination-path="pagination"
              @vuetable:pagination-data="onPaginationData"
            >
              <template slot="created_at" slot-scope="prop">
                <span>{{ prop.rowData.created_at || "-" }}</span>
              </template>
              <template slot="active_date" slot-scope="prop">
                <span>{{ prop.rowData.active_at || "-" }}</span>
              </template>
              <template slot="top-treshold" slot-scope="prop">
                <span>{{ prop.rowData.currency_threshold_max || "-" }}</span>
              </template>
              <template slot="bottom-treshold" slot-scope="prop">
                <span>{{ prop.rowData.currency_threshold_min || "-" }}</span>
              </template>
              <span
                slot="status"
                slot-scope="prop"
                :class="{
                  'text-warning':
                    prop.rowData.status !== null && prop.rowData.status === 0,
                  'text-success': prop.rowData.status === 1,
                  'text-danger':
                    prop.rowData.status !== 0 && prop.rowData.status !== 1
                }"
              >
                {{ prop.rowData.status_text }}
              </span>
              <template slot="created-by" slot-scope="prop">
                <span class="table-column-created-by">
                  <div v-if="prop.rowData.created_by">
                    <p class="m-0">
                      <strong>
                        {{ prop.rowData.created_by.name || "-" }}
                      </strong>
                    </p>
                    <p>
                      ({{
                        prop.rowData.created_by.email &&
                        prop.rowData.created_by.email !== "-"
                          ? prop.rowData.created_by.email
                          : "-"
                      }})
                    </p>
                  </div>
                  <span v-else>-</span>
                </span>
              </template>
            </vuetable>
            <div
              class="vuetable-pagination ui basic segment grid"
              v-show="!loadingTable"
            >
              <div class="wrapper-pagination-custom">
                <div class="pagination-custom-info">
                  <p>{{ this.paginationInfo }}</p>
                </div>
                <div class="pagination-custom-button">
                  <button
                    :disabled="current_page === 1"
                    @click="doMovePage('first')"
                  >
                    <i
                      v-if="current_page === 1"
                      class="fa fa-angle-double-left disabled"
                    ></i>
                    <i v-else class="fa fa-angle-double-left"></i>
                  </button>
                  <button
                    :disabled="current_page === 1"
                    @click="doMovePage('prev')"
                  >
                    <i
                      v-if="current_page === 1"
                      class="fa fa-angle-left disabled"
                    ></i>
                    <i v-else class="fa fa-angle-left"></i>
                  </button>
                  <div>{{ this.current_page }}</div>
                  <button
                    :disabled="data.length < 10"
                    @click="doMovePage('next')"
                  >
                    <i
                      v-if="data.length < 10"
                      class="fa fa-angle-right disabled"
                    ></i>
                    <i v-else class="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import moment from "moment";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import _ from "lodash";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetableRowHeader
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      page: 1,
      current_page: 1,
      limit: 10,
      type: "",
      data: [],
      dataSummaries: {
        buyPrice: {
          gold: "$ 0",
          min: "$ 0",
          max: "$ 0"
        },
        sellPrice: {
          gold: "$ 0",
          min: "$ 0",
          max: "$ 0"
        },
        createdAt: "-",
        createdBy: "-"
      },
      dataCheck: [],
      currentDate: "-",
      filterDate: "",
      filterDateError: null,
      startDate: "",
      endDate: "",
      dateType: "created_at",
      optionsDateType: [
        { value: "created_at", text: "Created At" },
        { value: "active_at", text: "Active At" }
      ],
      loadingTable: false,
      isLoading: false,
      isLoadingExport: false,
      isExport: false,
      filterText: "",
      errorResponse: "",
      reason: "",
      reasonId: "",
      message: "",
      paginationInfo: "",
      dateDetail: {
        start_from: "",
        end_from: ""
      },
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `currency-threshold`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "created_at|desc",
      sortOrder: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "asc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "created_at",
          title: "Created at"
        },
        {
          name: "active_at",
          sortField: "active_at",
          title: "Active Date"
        },
        {
          name: "buy_price_threshold_max",
          sortField: "buy_price_threshold_max",
          title: "Top buy threshold USD-IDR"
        },
        {
          name: "buy_price_threshold_min",
          sortField: "buy_price_threshold_min",
          title: "Bottom buy threshold USD-IDR"
        },
        {
          name: "sell_price_threshold_max",
          sortField: "sell_price_threshold_max",
          title: "Top sell threshold USD-IDR"
        },
        {
          name: "sell_price_threshold_min",
          sortField: "sell_price_threshold_min",
          title: "Bottom sell threshold USD-IDR"
        },
        {
          name: "status",
          title: "Status"
        },
        {
          name: "created-by",
          title: "Created By"
        }
      ]
    };
  },
  computed: {
    stateButton() {
      const filters = this.startDate === "" && this.endDate === "";
      const params =
        Object.entries(this.moreParams).filter(
          ([key, value]) => key !== "filter_date_type" && value
        ).length == 0;

      return (filters && params) || this.filterDateError;
    },
    stateButtonExport() {
      return (
        !(this.isExport && this.startDate && this.endDate) ||
        this.isLoadingExport
      );
    },
    buttonStyle() {
      return {
        background: !this.stateButton ? "#7565F6" : "#c8ced3",
        color: !this.stateButton ? "#FFFFFF" : "inherit"
      };
    },
    statusColor(status) {
      if (status !== null && status >= 0) {
        let colorStatus = "inherit";

        switch (status) {
          // Created
          case 0:
            colorStatus = "#D1B331";
            break;
          // Active
          case 1:
            colorStatus = "#35AA4B";
            break;
          // Inactive
          case 2:
            colorStatus = "#CB211F";
            break;
          default:
            colorStatus = "inherit";
            break;
        }

        return { color: colorStatus };
      }

      return { color: "inherit" };
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  methods: {
    onFetchSummary() {
      this.isLoading = true;

      this.$http
        .get("/gold-price-threshold-summary")
        .then(response => {
          if (response) {
            const res = response?.data?.data;

            this.dataSummaries = {
              buyPrice: {
                gold: res?.buy_rate === null ? "-" : res?.buy_rate || "$ 0",
                min: res?.buy_price_threshold_min || "$ 0",
                max: res?.buy_price_threshold_max || "$ 0"
              },
              sellPrice: {
                gold: res?.sell_rate === null ? "-" : res?.sell_rate || "$ 0",
                min: res?.sell_price_threshold_min || "$ 0",
                max: res?.sell_price_threshold_max || "$ 0"
              },
              createdAt: res?.created_at || "-",
              createdBy: res?.created_by || "-"
            };

            this.isLoading = false;
          }
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    onFetch() {
      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.loadingTable = true;
      this.data = [];

      this.$http
        .get(
          `gold-price-threshold?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=${this.limit}&${newParams}`
        )
        .then(response => {
          const datas = response.data.data;
          const datasCount = datas.length;
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = Math.min(
            startIndex + this.limit,
            datasCount > 0 ? datasCount + startIndex : this.limit
          );

          this.data = datas;
          this.current_page = response.data.current_page;
          this.paginationInfo =
            datas.length > 0
              ? `Displaying ${startIndex + 1} to ${endIndex}`
              : "No relevant data";

          this.loadingTable = false;
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    doFilter() {
      this.$events.$emit("apply-filter");
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    exportTable() {
      this.isLoadingExport = true;
      this.$http
        .get(
          `gold-price-threshold-export?filter_date_type=${this.dateType}&start_from=${this.startDate}&end_to=${this.endDate}`
        )
        .then(result => {
          if (result) {
            const exportPath = result.data.meta.data;
            window.location.href = exportPath;
          }
        })
        .catch(error => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;

            this.handleLoadError(error);
          }
        })
        .finally(() => {
          this.isLoadingExport = false;
        });
    },
    handleLoadError(error) {
      const code = error.response.data.meta.code;
      const message = error.response.data.meta.message;

      this.isLoading = false;
      this.isLoadingOTP = false;
      this.errors.code = code;
      this.errors.message = message;
      this.errors.status = code;

      if (code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (code == 403) {
        this.$router.push("/403");
      } else {
        this.$swal.fire("Failed!", message, "error");
      }
    },
    resetDateFilter() {
      const todayStart = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const todayEnd = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.filterDate = `${todayStart} - ${todayEnd}`;
      this.filterDateError = null;
      this.endDate = "";
      this.startDate = "";
      this.isExport = false;

      const datePicker = $('input[name="filterDate"]');
      datePicker.data("daterangepicker").setStartDate(moment().startOf("day"));
      datePicker.data("daterangepicker").setEndDate(moment().endOf("day"));

      // this.$events.$emit("date-filter-reset");
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      console.log("paginationData", paginationData);
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onFilterSet() {
      this.isExport = true;
      this.page = 1;
      this.moreParams = {
        filter_date_type: this.dateType,
        start_from: this.startDate,
        end_to: this.endDate
      };

      this.onFetch();
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "start_from" && key !== "end_to"
        )
      );
      this.moreParams = {
        ...newParams,
        start_from: this.startDate,
        end_to: this.endDate
      };
    },
    onDateFilterReset() {
      this.page = 1;
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "start_from" && key !== "end_to"
        )
      );
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else {
        this.page++;
        this.onFetch();
      }
    },
    handleGetCurrentDate() {
      const date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.currentDate = date;
    }
  },
  mounted() {
    this.onFetchSummary();
    this.handleGetCurrentDate();
    this.$events.$on("apply-filter", () => this.onFilterSet());
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));

    const onDateSelected = (start, end) => {
      const startValue = start.format("YYYY-MM-DD HH:mm:ss");
      const endValue = end.format("YYYY-MM-DD HH:mm:ss");

      const pastYear = moment(startValue)
        .subtract(11, "month")
        .startOf("month")
        .startOf("day");
      const upcomingYear = moment(startValue)
        .add(11, "month")
        .endOf("month")
        .endOf("day");

      if (
        moment(endValue).isBefore(pastYear) ||
        moment(endValue).isAfter(upcomingYear)
      ) {
        this.filterDateError = "Date Range cannot be more than 1 year";
      } else {
        this.filterDateError = null;
      }

      this.filterDate = `${startValue} - ${endValue}`;
      this.startDate = startValue;
      this.endDate = endValue;
      startDate = startValue;
      endDate = endValue;
    };

    $(async function() {
      const datePicker = $('input[name="filterDate"]');

      datePicker.daterangepicker(
        {
          ranges: {
            "Last 7 Days": [
              moment()
                .subtract(6, "days")
                .startOf("day"),
              moment().endOf("day")
            ],
            "Last 30 Days": [
              moment()
                .subtract(29, "days")
                .startOf("day"),
              moment().endOf("day")
            ],
            "This Month": [
              moment()
                .startOf("month")
                .startOf("day"),
              moment()
                .endOf("month")
                .endOf("day")
            ],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month")
                .startOf("day"),
              moment()
                .subtract(1, "month")
                .endOf("month")
                .endOf("day")
            ],
            "This Year": [
              moment()
                .startOf("month")
                .startOf("day"),
              moment()
                .add(11, "month")
                .endOf("month")
                .endOf("day")
            ],
            "Last Year": [
              moment()
                .subtract(11, "month")
                .startOf("month")
                .startOf("day"),
              moment()
                .endOf("month")
                .endOf("day")
            ]
          },
          singleDatePicker: false,
          alwaysShowCalendars: true,
          autoUpdateInput: true,
          readonly: true,
          drops: "auto",
          timePicker: true,
          timePickerSeconds: true,
          locale: {
            applyLabel: "Apply Date",
            format: "YYYY-MM-DD HH:mm:ss"
          }
        },
        function(start, end) {
          onDateSelected(start, end);
        }
      );
    });
  }
};
</script>
<style>
.reason-wrapper {
  width: 100%;
  min-height: 60px;
  text-align: center;
}

.custom-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status-red {
  color: #cb211f;
  text-decoration: underline;
  cursor: pointer;
}

.text-danger {
  color: #cb211f !important;
}

.form-rejection {
  width: "300px";
  background-color: #e77471;
}

.btn-alternate {
  background: #7565f6 !important;
  color: #fff;
}

.btn-alternate:hover {
  background: #604cf8 !important;
  color: #fff !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sk-three-bounce.loading-btn {
  margin: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.sk-three-bounce.loading-btn .sk-child {
  width: 8px;
  height: 8px;
  opacity: 0.6;
}

footer.modal-footer {
  display: none;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-type,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-filter {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto;
}

.header-card p {
  font-weight: bold;
}

.header-card span {
  margin-left: 10px;
  font-weight: normal;
}

.card-treshold-type {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}

.content-treshold {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
}

.content-treshold h5 {
  font-weight: bold;
}

.item-content-treshold {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-content-treshold p {
  margin: unset;
  font-size: 14px;
}
.item-content-treshold p.value {
  font-weight: bold;
}

.icon-treshold {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
}
.icon-treshold.icon-treshold-top {
  background-color: #20a8d8;
}
.icon-treshold.icon-treshold-bottom {
  background-color: #f86c6b;
}

.prepend-dateType {
  background: #c8ced3;
  border-radius: 0.25rem 0 0 0.25rem;
}
.prepend-dateType .custom-select {
  color: #23282c;
  background: transparent;
  border: unset;
}

.table-header {
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
