<template>
  <div className="animated">
    <b-card class="treshold-control" style="padding: unset;">
      <b-card-header>
        Gold Threshold Control
      </b-card-header>
      <b-card-body class="p-4">
        <treshold-control-table></treshold-control-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import TresholdControlTable from "./Table";

export default {
  name: "threshold-control",
  components: {
    TresholdControlTable
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.treshold-control .card-body {
  padding: unset;
}
</style>
